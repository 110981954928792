button{
	// button reset
	display: inline-block;
	cursor: pointer;
	margin: 0;
	padding: 0;
	border: 0;
	background-color: transparent;
}
.btn{
	position: relative;
	z-index: 3;
	display: inline-block;
	border-radius: 1.6rem;
	border: none;
	font-size: 1.8rem;
	font-weight: 300;
	box-shadow: 4px 7px 8px rgba(0, 0, 0, 0.35);
	border: 0;
	background-color: transparent;
	color: $white;
	transition: all 300ms ease;
	outline: none;

	// &-bg {
	// 	margin-top: 1rem;
	// 	margin-right: 1rem;
	// 	background-image: url("../images/useful/bg/button-bg.png");
	// 	background-size: cover;
	// 	z-index:1;
	// }
	&:hover{
		color: #ffe89c;
		// border-color: @White40;
		// background-color: @White80;
		// color: @CapeCod;
		outline: none;
	}
	&:focus,
	&:active{
		// border-color: @White40;
		// background-color: @Concrete;
		// color: @CapeCod;
		outline: none;
	}
	// colors
	&_white{
	}
	// states
	// a&{
	//     text-decoration: none;
	// }
	&_icon{
	}
	// sizes
	&_md{
	}
	&_sm{
	}
	&_xs{
	}
	&_light {
		font-weight: 300;
	}
}

.send-request {
	text-align: center;
}

.btn_green {
	height: 4.1rem;
	line-height: 4.1rem;
	padding: 0;
	&:before {
		content: '';
		position: absolute;
		left: -0.6rem;
		top: 0.6rem;
		z-index: -1;
		display: inline-block;
		width: 2.9rem;
		height: 2.9rem;
		background: #39b015;
		border-radius: 1rem;
		transform: rotate(45deg);
		@media ( max-width: $sw-xs-max){
			top: 0.2rem;
			left: -0.1rem;
			width: 2.1rem;
			height: 2.1rem;
			border-radius: 0.8rem;
		}
	}

	&:after {
		content: '';
		position: absolute;
		right: -0.6rem;
		top: 0.6rem;
		z-index: -1;
		display: inline-block;
		width: 2.9rem;
		height: 2.9rem;
		background: #72e350;
		border-radius: 1rem;
		transform: rotate(45deg);
		@media ( max-width: $sw-xs-max){
			top: 0.2rem;
			right: -0.1rem;
			width: 2.1rem;
			height: 2.1rem;
			border-radius: 0.8rem;
		}
	}

	@media ( max-width: $sw-xs-max){
		padding: 0;
		height: 2.5rem;
		line-height: 2.5rem;
		font-size: 1.2rem;
	}
	span {
		background: linear-gradient( to left, #72e350, #39b015);
		padding-right: 3.5rem;
		padding-left: 3.5rem;
		border-radius: 1.6rem;
		position: relative;
		display: block;
		@media ( max-width: $sw-xs-max){
			padding: 0 1rem;
			height: 2.5rem;
			line-height: 2.5rem;
			font-size: 1.2rem;
		}
	}
}


.btn_blue {
	padding: 0;
	background-image: -webkit-linear-gradient(right, #1db2e4 0%, #4790aa 100%);
	background-image: -o-linear-gradient(right, #1db2e4 0%, #4790aa 100%);
	background-image: linear-gradient(to left, #1db2e4 0%, #4790aa 100%);
	&:before {
	  content: '';
	  position: absolute;
	  left: -0.9rem;
	  top: 0.6rem;
	  z-index: -1;
	  display: inline-block;
	  width: 4rem;
	  height: 4rem;
	  background: #4790aa;
	  border-radius: 1rem;
	  transform: rotate(45deg);
	}

	&:after {
	  content: '';
	  position: absolute;
	  right: -0.9rem;
	  top: 0.6rem;
	  z-index: -1;
	  display: inline-block;
	  width: 4rem;
	  height: 4rem;
	  background: #1db2e4;
	  border-radius: 1rem;
	  transform: rotate(45deg);
	}
	
	span {
		height: 5.3rem;
		line-height: 5.3rem;
		background: linear-gradient(to left, #1db2e4 0%, #4790aa 100%);
		border-radius: 1.6rem;
		position: relative;
		display: block;
	}
}

