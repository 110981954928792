.footer {
	padding: 2.7rem 0 5.7rem;
	background-color: #262930;
	flex-shrink: 0;
	&-container {
		display: flex;
		// justify-content: space-between; 
		// align-items: flex-start;
	}

	&-contacts {
		margin-top: 0.1rem;
		flex-shrink: 0;
		&-info {
			line-height: 1.9rem;
			margin-bottom: 0;
			font-size: 1.6rem;
			color: $white;
			font-weight: 300;
			&__name,
			&__addrs,
			&__time-work {
				margin-bottom: 0;
			}
		}
	}

	&-lic {
		margin-top: 5.8rem;
		padding: 0 7.5rem;
		text-align: center;
		&__deskr {
			margin-bottom: 0.5rem;
			line-height: 1.8rem;
			text-align: left;
			font-size: 1.4rem;
			font-weight: 300;
			color: $white;
		}

		&__link {
			line-height: 2rem;
			font-size: 1.6rem;
			font-weight: 300;
			color: #4cd1fd;
		}
	}
}

@media ( max-width: $sw-md-max){
	.footer {
		&-container {
			flex-wrap: wrap;
			justify-content: space-between;
		}

		&-contacts {
			margin-bottom: 3rem;
			width: 25%;
			order: 1;
			&-info {
				&__name,
				&__addrs,
				&__time-work {
					font-size: 1.6rem;
				}
			}
		}

		&-lic {
			padding: 0 4rem;
			width: 45%;
			order: 2;
		}
	}

	.send-request {
		order: 3;
	}
}

@media ( max-width: $sw-md-min){
	.footer {
		&-container {
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 0 4rem;
		}

		&-contacts {
			margin-bottom: 3rem;
			width: 40%;
			order: 1;
		}

		&-lic {
			margin-top: 0;
			padding: 0;
			width: 100%;
			order: 3;
		}
	}

	.send-request {
		order: 2;
	}
}

@media ( max-width: $sw-xs-max){
	.footer {
		&-container {
			flex-wrap: wrap;
			padding: 0;
			max-width: 30rem;
			margin: auto;
		}
		&-contacts {
			margin-bottom: 1rem;
			width: 100%;
			&-info {
				&__name,
				&__addrs,
				&__time-work {
					font-size: 1.4rem;
				}
			}
		}
		&-lic {
			margin-top: 2rem;
			padding: 0;
			width: 100%;
			order: 3;
			&__link {
				font-size: 1.4rem;
			}
		}
	}

	&__send-request {
		margin-bottom: 0;
		order: 2;
	}
}