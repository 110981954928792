
.c-action {
	&-container {
		justify-content: flex-end;
		position: relative;
		display: flex;
	}
	&-section {
		margin-top: -6.2rem;
		margin-bottom: 16rem;
		@media ( max-width: $sw-xs-max){
			margin-bottom: 10rem;
		}
	}
	&-img {
		position: absolute;
		top: 3.5rem;
		left: -8rem;
		z-index: 4;
		width: 84.8rem;
		pointer-events: none;
	}

	&-form-inner {
		position: relative;
		width: 50.5rem;
		right: 0;

		&:before {
			content: '';
			position: absolute;
			left: -3rem;
			z-index: 1;
			width: 100vw;
			border-radius: 2rem;
			background-image: linear-gradient(to top, #24a1ca 0%, #71d9fb 57%, #71d9fb 100%);
			opacity: 0.25;
			top: 3.6rem;
			bottom: -3.4rem;
		}
		&:after {
			top: 0;
			bottom: 0;
			content: '';
			position: absolute;
			z-index: 2;
			top: 0;
			left: 100%;
			width: 100vw;
			box-shadow: inset -20px 3px 30px 5px rgba(87, 204, 243, 0.1);
			background-color: $white;
			border: 1px solid #57ccf3;
			border-left: none;
		}
	}

	&-form {
		padding: 3rem 0 4.4rem 3rem;
		box-shadow: inset 20px 3px 30px 5px rgba(87, 204, 243, 0.1);
		border: 1px solid #57ccf3;
		border-right: none;
		border-radius: 2rem 0 0 2rem;
		background-color: #ffffff;
		position: relative;
		z-index: 3;
		&__title {
			margin-bottom: 0.9rem;
			font-size: 3.6rem;
			font-weight: 700;
			color: #57ccf3;
			&_down {
				margin-bottom: 0.2rem;
			}
		}
		&__descr {
			padding-left: 0.4rem;
			color: #1a1a1a;
			font-size: 2.1rem;
			margin-bottom: 3rem;
			line-height: 2.9rem;
			font-weight: 300;
			&_down {
				margin-bottom: 1.1rem;
			}
		}
	}
}

.form {
	&__input-phone {
		margin-right: 1rem;
		margin-bottom: 1.5rem;
		width: 23.1rem;
		height: 4.7rem;
		box-shadow: inset 3px 4px 5px rgba(0, 0, 0, 0.45);
		background-color: #ffffff;
		border: none;
		border-radius: 1rem;
		font-size: 2rem;
	}

	&__btn {
		margin-bottom: 1rem;
	}

	&__label-lic {
		&-check {
			margin-left: 0.5rem;
			vertical-align: top;
			width: 2.6rem;
			height: 2.7rem;
			box-shadow: inset 3px 4px 5px rgba(0, 0, 0, 0.4);
			background-color: #ffffff;
			border: none;
			border-radius: 1rem;
		}

		span {
			text-align: left;
			margin-top: 0.4rem;
			margin-left: 1.5rem;
			display: inline-block;
			width: 85%;
			color: #1a1a1a;
			line-height: 1.5rem;
			font-size: 1.4rem;
			font-weight: 300;
		}
	}
}

@media (max-width: $sw-md-max){
	.c-action {
		&-img {
			max-width: 80%;
			top: 10rem;
			left: -15rem;
			z-index: 4;
			width: 84.8rem;
			pointer-events: none;
		}
	}
}


@media ( max-width: $sw-sm-max){
	.c-action {
		&-img {
			top: 10rem;
			left: -17rem;
			z-index: 4;
			width: 94.8rem;
			max-width: 100%;
			pointer-events: none;
		}

		&-form {
			&-inner {
				width: 37.5rem;
			}
			&__title {
				font-size: 2.3rem;
			}
			&__descr {
				font-size: 1.5rem;
				line-height: 2rem;
			}
		}
	}

	.form {
		&__input-phone {
			width: 100%;
			height: 3rem;
		}

		&__label-lic {
			&-check {
				width: 2rem;
				height: 2rem;
				box-shadow: inset 3px 4px 5px rgba(0, 0, 0, 0.4);
			}

			span {
				text-align: left;
				font-size: 1rem;
				line-height: 1.2rem;
				width: 80%;
			}

			br {
				display: none;
			}
		}
	}
}
@media (max-width: $sw-xs-max){
	.c-action {
		&-img {
			display: none;
		}

		&-form {
			&-inner {
				width: 100%;
			}
		}
	}
}

.clearfix:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}