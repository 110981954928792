.c-offer {
	position: relative;
	z-index: 2;
	margin-top: -18.3rem;
	background-image: url("../images/useful/bg/offer-bg-new.png");
	background-position: bottom;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	
	&-inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 14.4rem 0 21rem;
		position: relative;
		z-index: 2;
		@media ( max-width: $sw-sm-max){
			padding: 15rem 0 8rem;
		}
		@media ( max-width: $sw-xs-max){
			padding: 12rem 0 8rem;
		}
	}

	&-caption {
		&__title {
			width: 70%;
			margin: auto;
			text-align: center;
			font-size: 3.6rem;
			font-weight: 400;
			margin-bottom: 0.9rem;
			color: $maya-blue;
		}
		&__text {
			margin-bottom: 3.4rem;
			text-align: center;
			color: $white;
			font-size: 2.1rem;
			font-weight: 300;
		}
	}

	&-wrap {
		display: flex;
		margin-bottom: 4.8rem;
		&__item {
			padding: 4rem  1rem;
			width: 29rem;
			text-align: center;
			border: 1px solid $white;
			&-img {
				&-cont {
					position: relative;
					margin-bottom: 2rem;
					height: 5.6rem;

					.icon {
						font-size: 6.2rem;
					}
				}
			}
			&-title {
				font-size: 1.7rem;
				margin-bottom: 1.5rem;
				color: $maya-blue;
				text-transform: uppercase;
			}
			&-list {
				padding: 0;
			}
			&-item {
				color: $white;
				list-style: none;
				font-size: 1.8rem;
				font-weight: 300;
				line-height: 2.1rem;
			}
		}
	}
}

.c-offer-button-cont {
	text-align: center;
}


@media ( max-width: $sw-lg-min){
	.c-offer {
		background-size: auto 100%;
		background-position: center;
	}
}


@media ( max-width: $sw-sm-max){
	.c-offer {
		background-size: auto 130%;
		background-position: center 30%;
		&-caption {
			&__title {
				width: 100%;
				font-size: 2.5rem;
			}
			&__text {
				font-size: 2rem;
				line-height: 2rem;
			}
		}
		&-wrap {
			flex-wrap: wrap;
			&__item {
				width: 50%;
				&-title {
					font-size: 2rem;
				}
				&-item {
					font-size: 1.6rem;
				}
			}
		}
	}

	.btn_blue {
		font-size: 1.5rem;
	}
}

@media ( max-width: $sw-md-min){
	.c-offer {
		background-size: auto 124%;
		background-position: center 30%;
		&-caption {
			&__title {
				font-size: 3rem;
			}
			&__text {
				font-size: 2rem;
			}
		}
		&-wrap {
			flex-wrap: wrap;
			&__item {
				width: 50%;
				&-title {
					font-size: 1.6rem;
				}
				&-item {
					font-size: 1.2rem;
				}
			}
		}
	}
}

@media ( max-width: $sw-xs-max){
	.c-offer {
		background-size: auto 130%;
		background-position: center 30%;
		&-caption {
			width: 100%;
			&__title {
				font-size: 2.5rem;
			}
			&__text {
				font-size: 1.6rem;
				line-height: 2rem;
			}
		}
		&-wrap {
			flex-wrap: wrap;
			&__item {
				width: 100%;
				font-size: 1.6rem;
			}
		}
	}

	.btn_blue {
		font-size: 1.5rem;
	}
}
