.c-clients {
	background-image: url("../images/useful/bg/clients-bg.png");
	background-size: 100% 100%;
	background-position: bottom;
	&-container {
		padding-top: 10rem;
		@media ( max-width: $sw-xs-max){
			padding-top: 5rem;
		}
		&__title {
			margin-bottom: 5rem;
			text-align: center;
			color: $maya-blue;
			font-size: 3.6rem;
			font-weight: 400;
		}
	}

	&-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;

		&__link {
			width: 20.5%;
			margin-bottom: 5rem;
		}

		&__img {
			display: block;
			margin: 0 auto;
		}

		&__deskr {
			margin-top: -5rem;
			margin-bottom: 20rem;
			width: 100%;
			font-size: 2.4rem;
			font-weight: 300;
			color: #262930;
			@media ( max-width: $sw-md-max){
				font-size: 1.6rem;
			}
		}
	}
}

@media ( max-width: $sw-md-max){
	.c-clients {
		&-container__title {
			font-size: 3rem;
		}

		&-wrap__link {
			padding: 0 1rem;
			width: 25%;
		}
	}
}

@media ( max-width: $sw-sm-max){
	.c-clients {
		background-size: auto 100%;
		background-position: bottom;
		&-container__title {
			font-size: 2.5rem;
		}

		&-wrap__link {
			width: 33%;
		}
	}
}