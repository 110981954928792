.c-we-work {
	padding-top: 10.5rem;
	background-image: url("../images/useful/bg/we-work-bg.png");
	background-size: 100% 130%;
	background-position: top 30%;
	background-repeat: no-repeat;
	padding-bottom: 4.3rem;
	@media ( max-width: $sw-xs-max){
		padding-top: 5rem;
		padding-bottom: 0;
	}
	&-wrap {
		display: flex;
		justify-content: space-between;
	}

	&__title {
		margin-bottom: 8rem;
		text-align: center;
		color: $maya-blue;
		font-size: 4.8rem;
		font-weight: 400;
	}

	&-item {
		width: 16rem;
		position: relative;
		&:not(:first-child):before {
			content: '';
			position: absolute;
			left: -1.4rem;
			top: 3rem;
			display: block;
			height: 2.4rem;
			width: 2.4rem;
			transform: rotate(45deg);
			border-top: 1px solid #4cd1fd;
			border-right: 1px solid #4cd1fd;
		}

		&:not(:first-child):after {
			content: '';
			position: absolute;
			left: -3rem;
			top: 3rem;
			display: block;
			height: 2.4rem;
			width: 2.4rem;
			transform: rotate(45deg);
			border-top: 1px solid rgba(76, 209, 253, 0.2);
			border-right: 1px solid rgba(76, 209, 253, 0.2);
		}

		&__icon {
			margin: 0 auto;
			margin-bottom: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 8.7rem;
			height: 8.7rem;
			border: 1px solid rgba(25, 196, 252, 0.2);
			border-radius: 50%;
			&_circl {
				position: relative;
				width: 6.9rem;
				height: 6.9rem;
				border: 1px solid #19c4fc;
				border-radius: 50%;
				.icon {
					color: #4cd1fd;
					font-size: 4rem;
				}
			}
		}

		&__text {
			font-size: 1.6rem;
			font-weight: 300;
			line-height: 2rem;
			text-align: center;
			@media ( max-width: $sw-md-max){
				font-size: 1.2rem;
				line-height: 1.6rem;
			}
		}

	}
}

@media ( max-width: $sw-md-min){
	.c-we-work{
		&__title {
			margin-bottom: 3rem;
			font-size: 3rem;
		}
		&-wrap {
			flex-wrap: wrap;
			justify-content: space-around;
		}

		&-item {
			width: 25%;
		}
	}
}

@media ( max-width: $sw-xs-max){
	.c-we-work{
		background-size: auto 150%;
		background-position: center 25%;
		&__title {
			font-size: 2.5rem;
			margin-bottom: 0;
		}
		&-wrap {
			flex-wrap: wrap;
			margin: auto;
			padding: 5rem 0 0;
			width: 80%;
		}
		&-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 4rem;
			width: 100%;
			&__icon {
				flex-shrink: 0;
				margin: 0;
			}

			&__text {
				margin-left: 3rem;
				width: 100%;
				text-align: left;
				font-size: 1.6rem;
			}

			&:not(:first-child):before {
				// left: calc( 50% - 1.2rem);
				left: 3rem;
				top: -3.6rem;
				transform: rotate(135deg);
			}

			&:not(:first-child):after {
				// left: calc( 50% - 1.2rem);
				left: 3rem;
				top: -4.6rem;
				transform: rotate(135deg);
			}
		}
	}
}