.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
}
  
  /* ==========================================
  Single-colored icons can be modified like so:
  .icon-name {
	font-size: 32px;
	color: red;
  }
  ========================================== */

.icon_online_payment {
	width: 1.400820793433653;
}



.icon {
	font-size: 5rem;
	color: white;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
}

.icon-logo {
	position: static;
	width: 3.477961432506887em;
	font-size: 8.7rem;
	@media ( max-width: $sw-xs-min) {
	font-size: 4.5rem;
	}
}
