.c-warranties {
	margin-top: -35rem;
	position: relative;
	z-index: 1;
	&-inner {
		position: relative;
		z-index: 1;
		background-image: url("../images/useful/bg/warranties-bg.jpg");
		background-size: cover;
		background-position: center;
	}

	&-block {
		position: relative;
		margin-top: -20rem;
		width: 50%;
		padding: 34.7rem 0 3rem;
		z-index: 2;
		&_bg {
			position: absolute;
			z-index: 2;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-image: linear-gradient(to right, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 0.8;
		}
		&__title {
			margin-bottom: 2rem;
			color: $maya-blue;
			font-size: 4.8rem;
			font-weight: 400;
			line-height: 2.9rem;
		}
		&__text-title {
			margin-bottom: 2.5rem;
			color: #212121;
			line-height: 2.5rem;
			font-size: 2.1rem;
			font-weight: 400;
		}
		&__text {
			margin-bottom: 2.5rem;
			color: #212121;
			font-size: 1.8rem;
			font-weight: 300;
			line-height: 2.9rem;
		}
		&__text span {
			color: #27b4e5;
			font-size: 2.4rem;
			font-weight: 700;
		}
	}

	&-button-cont {
		text-align: center;
		&__text {
			font-size: 2.1rem;
		}
	}
}

@media ( max-width: $sw-md-max){
	.c-warranties-block {
		margin-top: 16rem;
		padding-top: 16rem;
		width: 100%;
		&__title {
			font-size: 2.5rem;
		}

		&__text-title {
			line-height: 2rem;
			font-size: 1.6rem;
		}

		&__text {
			font-size: 1.6rem;
			line-height: 2rem;
			span {
				font-size: 2rem;
			}
		}
	}
}

@media ( max-width: $sw-sm-max){
	.c-warranties-block {
		margin-top: 28.4rem;
		padding-top: 10rem;
		width: 100%;
		&__title {
			font-size: 2.5rem;
		}

		&__text-title {
			line-height: 2rem;
			font-size: 1.6rem;
		}

		&__text {
			font-size: 1.6rem;
			line-height: 2rem;
			span {
				font-size: 2rem;
			}
		}
	}
}

@media ( max-width: $sw-xs-max){
	.c-warranties-button-cont__text {
		font-size: 1.6rem;
		line-height: 2rem;
	}
}