.modal {
	&-content {
		border-radius: 1.5rem;
	}

	&-header {
		position: relative;
		border-bottom: none;
	}

	&-body {
		padding: 2rem 3.5rem 3.5rem 3.5rem;
	}


	&-title {
		margin-top: 3.1rem;
		width: 100%;
		text-align: center;
		font-size: 2.4rem;
		font-weight: 700;
		color: $maya-blue;
	}

	.close {
		position: absolute;
		top: 0;
		right: 1rem;
		padding: 1rem;
		height: auto;
		box-sizing: border-box;
		span {
			display: inline-block;
			width: 1.3rem;
			height: 0.2rem;
			font-weight: 400;
			background-color: $maya-blue;
			transform: rotate(45deg);
			&:after {
				position: absolute;
				top: 0;
				right: 0;
				content: '';
				display: inline-block;
				width: 1.3rem;
				height: 0.2rem;
				font-weight: 400;
				background-color: $maya-blue;
				transform: rotate(-90deg);
			}
		}
	}
}

.form-control {
	display: inline-block;
	margin-right: 1rem;
	margin-bottom: 1.5rem;
	max-width: 18rem;
	height: 4.7rem;
	box-shadow: inset 3px 4px 5px rgba(0, 0, 0, 0.45);
	background-color: #ffffff;
	border: none;
	border-radius: 1rem;
	font-size: 2rem;

	&::placeholder {
		transition: opacity 0.3s ease;
		opacity: 1;
	}

	&:hover {
		box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, 0.45);
	}

	&[readonly] {
		&:hover {}
		&:focus {}
		&:active {}
	}
	&:focus {
		// box-shadow: none;

		&::placeholder{
			opacity: 0;
		}
	}
	&:active {
		// box-shadow: none;
	}
	&.error {
		border-color: #ff0000 !important;
		background-color: #ffdede;
	}

	&[contenteditable="true"],
	// textarea& {}
	&[contenteditable="true"] {}
	// textarea& {
	// 	min-height: 120px;
	// 	max-height: 500px;
	// 	resize: vertical;
	// }
	// input& {
	// 	height: 34px;
	// }
	&:disabled,
	&[disabled],
	&.disabled {
		.form-control {
			pointer-events: none;
			cursor: default;
			opacity: 0.5;
		}
	}
}
.form-group {
	display: inline-block;
	margin-bottom: 0;
	&:disabled,
	&[disabled],
	&.disabled {
		.form-control {
			pointer-events: none;
			cursor: default;
			opacity: 0.5;
		}
	}
}
.input-wrap,
.select-wrap {
	position: relative;
}
.select-wrap {}
.input-wrap {
	& > .ico {}
}
.bootstrap-select {
	&:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
		width: 100%;
	}
	&.btn-group.open .dropdown-toggle {
		.caret{
		}
		.bs-caret {
		}
	}
	& > .dropdown-toggle {
		&:hover {
			.bs-caret {}
		}
		&:focus {
		}
		&:active {}

		&.dropdown-toggle,
		&.bs-placeholder {
			&,
			&:hover,
			&:focus,
			&:active {
				outline: none!important;
			}
		}

		span {}
	}
	.dropdown-menu {
		&.open {}
		ul.dropdown-menu {
			& > li {
				&.selected.active {
					& > a {}
				}
				& > a {}
			}
		}
	}
}
label {
	margin-bottom: 0;
	font-weight: 300;
	cursor: pointer;

	&.error {
		color: #ff0000;
	}
}
.hidden-input {
    visibility: hidden !important;
	position: absolute !important;
	z-index: -1 !important;
    opacity: 0 !important;
    height: 0 !important;
    width: 0 !important;
    left: 0 !important;
    top: 0 !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
}
.field_clear_btn {
	transition: color 0.3s ease;
	margin-bottom: auto;
	position: absolute;
	margin-top: auto;
	display: block;
	color: #d8d8d8;
	height: 30px;
	width: 30px;
	bottom: 0;
	right: 0;
	top: 0;

	&:hover {
		border-color: #b61f27;
		color: #b61f27;
	}
	&:focus {}
	&:active {}
	&:before,
	&:after {
		background-color: currentColor;
		position: absolute;
		display: block;
		margin: auto;
		content: '';
		height: 2px;
		width: 16px;
		bottom: 0;
		right: 0;
		left: 0;
		top: 0;
	}
	&:before {
		transform: rotate(45deg);
	}
	&:after {
		transform: rotate(-45deg);
	}
}
.checkbox-label {
	position: relative;
	padding-left: 30px;
	font-size: 1.4rem;
	line-height: 1.5rem;

	&:disabled,
	&[disabled],
	&.disabled {
		pointer-events: none;
		cursor: default;
		opacity: 0.5;
	}

	.check-icon {
		position: absolute;
		display: block;
		// margin-right: 1rem;
		height: 2.6rem;
		width: 2.6rem;
		left: 0;
		top: 2px;
		box-shadow: inset 3px 4px 5px rgba(0, 0, 0, 0.45);
		background-color: #ffffff;
		border: none;
		border-radius: 0.5rem;

		&:before {
			transform: rotate(-52.5deg) skew(-15deg);
			transition: opacity 0.3s ease;
			border-bottom: solid 3px #72e350 ;
			border-left: solid 3px #39b015;
			position: absolute;
			display: block;
			margin: auto;
			content: '';
			height: 10px;
			width: 18px;
			opacity: 0;
			top: -8px;
			left: 4px;
			bottom: 0;
			right: 0;
		}
	}
	.check-value {
		// padding-left: 1rem;
		transition: color 0.3s ease;
		color: #323232;
		text-align: left;
	}
	input {
		&:checked {
			& ~ .check-icon {
				&:before {
					opacity: 1;
				}
			}
		}
		&:disabled {
			& ~ .check-icon {
				pointer-events: none;
				cursor: default;
				opacity: 0.5;
			}
			& ~ .check-value {
				pointer-events: none;
				cursor: default;
				opacity: 0.5;
			}
		}
	}
}
.radio-label {
	padding-left: 20px;
	position: relative;

	&:disabled,
	&[disabled],
	&.disabled {
		pointer-events: none;
		cursor: default;
		opacity: 0.5;
	}

	.check-icon{
		border: solid 1px #0171a6;
		border-radius: 50%;
		position: absolute;
		display: block;
		height: 13px;
		width: 13px;
		top: 4px;
		left: 0;

		&:before{
			transition: opacity 0.3s ease;
			background-color:  #0171a6;
			border-radius: 50%;
			position: absolute;
			display: block;
			margin: auto;
			content: '';
			height: 5px;
			width: 5px;
			opacity: 0;
			bottom: 0;
			right: 0;
			left: 0;
			top: 0;
		}
	}
	.check-value {
		transition: color 0.3s ease;
		color: #979797;
	}
	&.label_grey {
		.check-value {
			color: #979797;
		}
	}
	input {
		&:checked {
			& ~ .check-icon {
				&:before {
					opacity: 1;
				}
			}
		}
		&:disabled {
			& ~ .check-icon {
				pointer-events: none;
				cursor: default;
				opacity: 0.5;
			}
			& ~ .check-value {
				pointer-events: none;
				cursor: default;
				opacity: 0.5;
			}
		}
	}
}