html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

*,
*::before,
*::after {
  box-sizing: inherit; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 2.14286;
  color: #000;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: underline;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: left; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit; }

h1, .h1 {
  font-size: 3.6rem; }

h2, .h2 {
  font-size: 2.6rem; }

h3, .h3 {
  font-size: 2.4rem; }

h4, .h4 {
  font-size: 2rem; }

h5, .h5 {
  font-size: 1.8rem; }

h6, .h6 {
  font-size: 1.6rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #868e96; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f8f9fa;
  border-radius: 0.25rem; }
  a > code {
    padding: 0;
    color: inherit;
    background-color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #212529; }
  pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 480px) {
    .container {
      max-width: 100%; } }
  @media (min-width: 768px) {
    .container {
      max-width: 78rem; } }
  @media (min-width: 992px) {
    .container {
      max-width: 98rem; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 120.4rem; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16, .col-xs-17, .col-xs-18, .col-xs-19, .col-xs-20, .col-xs-21, .col-xs-22, .col-xs-23, .col-xs-24, .col-xs,
.col-xs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg,
.col-lg-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

@media (min-width: 480px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xs-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xs-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xs-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xs-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xs-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xs-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xs-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xs-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xs-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xs-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xs-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xs-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xs-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xs-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xs-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xs-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xs-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xs-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xs-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xs-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xs-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xs-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xs-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xs-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xs-1 {
    order: 1; }
  .order-xs-2 {
    order: 2; }
  .order-xs-3 {
    order: 3; }
  .order-xs-4 {
    order: 4; }
  .order-xs-5 {
    order: 5; }
  .order-xs-6 {
    order: 6; }
  .order-xs-7 {
    order: 7; }
  .order-xs-8 {
    order: 8; }
  .order-xs-9 {
    order: 9; }
  .order-xs-10 {
    order: 10; }
  .order-xs-11 {
    order: 11; }
  .order-xs-12 {
    order: 12; }
  .order-xs-13 {
    order: 13; }
  .order-xs-14 {
    order: 14; }
  .order-xs-15 {
    order: 15; }
  .order-xs-16 {
    order: 16; }
  .order-xs-17 {
    order: 17; }
  .order-xs-18 {
    order: 18; }
  .order-xs-19 {
    order: 19; }
  .order-xs-20 {
    order: 20; }
  .order-xs-21 {
    order: 21; }
  .order-xs-22 {
    order: 22; }
  .order-xs-23 {
    order: 23; }
  .order-xs-24 {
    order: 24; } }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-sm-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-sm-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-sm-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-md-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-md-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-md-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; } }

@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-lg-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-lg-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-lg-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e9ecef; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e9ecef; }
  .table tbody + tbody {
    border-top: 2px solid #e9ecef; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #e9ecef; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e9ecef; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddfe2; }

.table-hover .table-secondary:hover {
  background-color: #cfd2d6; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #cfd2d6; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.thead-inverse th {
  color: #fff;
  background-color: #212529; }

.thead-default th {
  color: #495057;
  background-color: #e9ecef; }

.table-inverse {
  color: #fff;
  background-color: #212529; }
  .table-inverse th,
  .table-inverse td,
  .table-inverse thead th {
    border-color: #32383e; }
  .table-inverse.table-bordered {
    border: 0; }
  .table-inverse.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-inverse.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 1199px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive.table-bordered {
      border: 0; } }

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1.4rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: none; }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block; }

.col-form-label {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  margin-bottom: 0; }

.col-form-label-lg {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem - 1px * 2);
  padding-bottom: calc(0.25rem - 1px * 2);
  font-size: 0.875rem; }

.col-form-legend {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: 1.4rem; }

.form-control-plaintext {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  line-height: 1.25;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.3125rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem; }
  .form-check.disabled .form-check-label {
    color: #868e96; }

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0; }

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem; }
  .form-check-input:only-child {
    position: static; }

.form-check-inline {
  display: inline-block; }
  .form-check-inline .form-check-label {
    vertical-align: middle; }
  .form-check-inline + .form-check-inline {
    margin-left: 0.75rem; }

.invalid-feedback {
  display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .invalid-feedback,
  .was-validated .form-control:valid ~ .invalid-tooltip, .form-control.is-valid ~ .invalid-feedback,
  .form-control.is-valid ~ .invalid-tooltip, .was-validated
  .custom-select:valid ~ .invalid-feedback,
  .was-validated
  .custom-select:valid ~ .invalid-tooltip,
  .custom-select.is-valid ~ .invalid-feedback,
  .custom-select.is-valid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
  color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description {
  color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 768px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-control-label {
      margin-bottom: 0;
      vertical-align: middle; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      margin-top: 0;
      margin-bottom: 0; }
    .form-inline .form-check-label {
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0; }
    .form-inline .custom-control-indicator {
      position: static;
      display: inline-block;
      margin-right: 0.25rem;
      vertical-align: text-bottom; }
    .form-inline .has-feedback .form-control-feedback {
      top: 0; } }

.btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 1.4rem;
  line-height: 1.25;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out; }
  .btn:focus, .btn:hover {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: .65; }
  .btn:active, .btn.active {
    background-image: none; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #0069d9;
    background-image: none;
    border-color: #0062cc; }

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #727b84;
    border-color: #6c757d; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #868e96;
    border-color: #868e96; }
  .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #727b84;
    background-image: none;
    border-color: #6c757d; }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    background-color: #218838;
    background-image: none;
    border-color: #1e7e34; }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    background-color: #138496;
    background-image: none;
    border-color: #117a8b; }

.btn-warning {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #111;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    background-color: #e0a800;
    background-image: none;
    border-color: #d39e00; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    background-color: #c82333;
    background-image: none;
    border-color: #bd2130; }

.btn-light {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #111;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    background-color: #e2e6ea;
    background-image: none;
    border-color: #dae0e5; }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    background-color: #23272b;
    background-image: none;
    border-color: #1d2124; }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:active, .btn-outline-primary.active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #868e96;
    background-color: transparent; }
  .btn-outline-secondary:active, .btn-outline-secondary.active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:active, .btn-outline-success.active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:active, .btn-outline-info.active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:active, .btn-outline-warning.active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:active, .btn-outline-danger.active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:active, .btn-outline-light.active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:active, .btn-outline-dark.active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }

.btn-link {
  font-weight: normal;
  color: #007bff;
  border-radius: 0; }
  .btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
    background-color: transparent; }
  .btn-link, .btn-link:focus, .btn-link:active {
    border-color: transparent;
    box-shadow: none; }
  .btn-link:hover {
    border-color: transparent; }
  .btn-link:focus, .btn-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: transparent; }
  .btn-link:disabled {
    color: #868e96; }
    .btn-link:disabled:focus, .btn-link:disabled:hover {
      text-decoration: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1200px) {
  .modal-lg {
    max-width: 800px; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #868e96 !important; }

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus, a.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50%; }

.rounded-0 {
  border-radius: 0; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

@media (min-width: 480px) {
  .d-xs-none {
    display: none !important; }
  .d-xs-inline {
    display: inline !important; }
  .d-xs-inline-block {
    display: inline-block !important; }
  .d-xs-block {
    display: block !important; }
  .d-xs-table {
    display: table !important; }
  .d-xs-table-cell {
    display: table-cell !important; }
  .d-xs-flex {
    display: flex !important; }
  .d-xs-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

.d-print-block {
  display: none !important; }
  @media print {
    .d-print-block {
      display: block !important; } }

.d-print-inline {
  display: none !important; }
  @media print {
    .d-print-inline {
      display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
  @media print {
    .d-print-inline-block {
      display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

@media (min-width: 480px) {
  .flex-xs-row {
    flex-direction: row !important; }
  .flex-xs-column {
    flex-direction: column !important; }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xs-wrap {
    flex-wrap: wrap !important; }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xs-start {
    justify-content: flex-start !important; }
  .justify-content-xs-end {
    justify-content: flex-end !important; }
  .justify-content-xs-center {
    justify-content: center !important; }
  .justify-content-xs-between {
    justify-content: space-between !important; }
  .justify-content-xs-around {
    justify-content: space-around !important; }
  .align-items-xs-start {
    align-items: flex-start !important; }
  .align-items-xs-end {
    align-items: flex-end !important; }
  .align-items-xs-center {
    align-items: center !important; }
  .align-items-xs-baseline {
    align-items: baseline !important; }
  .align-items-xs-stretch {
    align-items: stretch !important; }
  .align-content-xs-start {
    align-content: flex-start !important; }
  .align-content-xs-end {
    align-content: flex-end !important; }
  .align-content-xs-center {
    align-content: center !important; }
  .align-content-xs-between {
    align-content: space-between !important; }
  .align-content-xs-around {
    align-content: space-around !important; }
  .align-content-xs-stretch {
    align-content: stretch !important; }
  .align-self-xs-auto {
    align-self: auto !important; }
  .align-self-xs-start {
    align-self: flex-start !important; }
  .align-self-xs-end {
    align-self: flex-end !important; }
  .align-self-xs-center {
    align-self: center !important; }
  .align-self-xs-baseline {
    align-self: baseline !important; }
  .align-self-xs-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 480px) {
  .float-xs-left {
    float: left !important; }
  .float-xs-right {
    float: right !important; }
  .float-xs-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

@media (min-width: 480px) {
  .m-xs-0 {
    margin: 0 !important; }
  .mt-xs-0 {
    margin-top: 0 !important; }
  .mr-xs-0 {
    margin-right: 0 !important; }
  .mb-xs-0 {
    margin-bottom: 0 !important; }
  .ml-xs-0 {
    margin-left: 0 !important; }
  .mx-xs-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-xs-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-xs-1 {
    margin: 0.25rem !important; }
  .mt-xs-1 {
    margin-top: 0.25rem !important; }
  .mr-xs-1 {
    margin-right: 0.25rem !important; }
  .mb-xs-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xs-1 {
    margin-left: 0.25rem !important; }
  .mx-xs-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-xs-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-xs-2 {
    margin: 0.5rem !important; }
  .mt-xs-2 {
    margin-top: 0.5rem !important; }
  .mr-xs-2 {
    margin-right: 0.5rem !important; }
  .mb-xs-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xs-2 {
    margin-left: 0.5rem !important; }
  .mx-xs-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-xs-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-xs-3 {
    margin: 1rem !important; }
  .mt-xs-3 {
    margin-top: 1rem !important; }
  .mr-xs-3 {
    margin-right: 1rem !important; }
  .mb-xs-3 {
    margin-bottom: 1rem !important; }
  .ml-xs-3 {
    margin-left: 1rem !important; }
  .mx-xs-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-xs-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-xs-4 {
    margin: 1.5rem !important; }
  .mt-xs-4 {
    margin-top: 1.5rem !important; }
  .mr-xs-4 {
    margin-right: 1.5rem !important; }
  .mb-xs-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xs-4 {
    margin-left: 1.5rem !important; }
  .mx-xs-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-xs-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-xs-5 {
    margin: 3rem !important; }
  .mt-xs-5 {
    margin-top: 3rem !important; }
  .mr-xs-5 {
    margin-right: 3rem !important; }
  .mb-xs-5 {
    margin-bottom: 3rem !important; }
  .ml-xs-5 {
    margin-left: 3rem !important; }
  .mx-xs-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-xs-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-xs-0 {
    padding: 0 !important; }
  .pt-xs-0 {
    padding-top: 0 !important; }
  .pr-xs-0 {
    padding-right: 0 !important; }
  .pb-xs-0 {
    padding-bottom: 0 !important; }
  .pl-xs-0 {
    padding-left: 0 !important; }
  .px-xs-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-xs-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-xs-1 {
    padding: 0.25rem !important; }
  .pt-xs-1 {
    padding-top: 0.25rem !important; }
  .pr-xs-1 {
    padding-right: 0.25rem !important; }
  .pb-xs-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xs-1 {
    padding-left: 0.25rem !important; }
  .px-xs-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-xs-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-xs-2 {
    padding: 0.5rem !important; }
  .pt-xs-2 {
    padding-top: 0.5rem !important; }
  .pr-xs-2 {
    padding-right: 0.5rem !important; }
  .pb-xs-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xs-2 {
    padding-left: 0.5rem !important; }
  .px-xs-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-xs-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-xs-3 {
    padding: 1rem !important; }
  .pt-xs-3 {
    padding-top: 1rem !important; }
  .pr-xs-3 {
    padding-right: 1rem !important; }
  .pb-xs-3 {
    padding-bottom: 1rem !important; }
  .pl-xs-3 {
    padding-left: 1rem !important; }
  .px-xs-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-xs-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-xs-4 {
    padding: 1.5rem !important; }
  .pt-xs-4 {
    padding-top: 1.5rem !important; }
  .pr-xs-4 {
    padding-right: 1.5rem !important; }
  .pb-xs-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xs-4 {
    padding-left: 1.5rem !important; }
  .px-xs-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-xs-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-xs-5 {
    padding: 3rem !important; }
  .pt-xs-5 {
    padding-top: 3rem !important; }
  .pr-xs-5 {
    padding-right: 3rem !important; }
  .pb-xs-5 {
    padding-bottom: 3rem !important; }
  .pl-xs-5 {
    padding-left: 3rem !important; }
  .px-xs-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xs-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-xs-auto {
    margin: auto !important; }
  .mt-xs-auto {
    margin-top: auto !important; }
  .mr-xs-auto {
    margin-right: auto !important; }
  .mb-xs-auto {
    margin-bottom: auto !important; }
  .ml-xs-auto {
    margin-left: auto !important; }
  .mx-xs-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xs-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4 {
    margin-left: 1.5rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5 {
    margin-left: 3rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4 {
    padding-left: 1.5rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5 {
    padding-left: 3rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4 {
    padding-left: 1.5rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4 {
    margin-left: 1.5rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5 {
    margin-left: 3rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4 {
    padding-left: 1.5rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5 {
    padding-left: 3rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

@media (min-width: 480px) {
  .text-xs-left {
    text-align: left !important; }
  .text-xs-right {
    text-align: right !important; }
  .text-xs-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-normal {
  font-weight: normal; }

.font-weight-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important; }

.text-secondary {
  color: #868e96 !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #6c757d !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important; }

.text-muted {
  color: #868e96 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.h-reset-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0; }

html {
  height: 100%;
  font-size: 10px; }

body {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  letter-spacing: .015rem; }

img {
  max-width: 100%;
  height: auto; }

.h-object-fit {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: block; }
  .h-object-fit > img {
    position: absolute;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    height: auto;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    object-fit: cover;
    max-height: none;
    max-width: none;
    height: 100%; }
    .no-object-fit .h-object-fit > img,
    .no-objectfit .h-object-fit > img {
      display: none; }

@font-face {
  font-family: "Myriad Pro";
  src: url("../fonts/myriad-pro/myriadpro-bold/myriadpro-bold.eot");
  src: url("../fonts/myriad-pro/myriadpro-bold/myriadpro-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/myriad-pro/myriadpro-bold/myriadpro-bold.woff") format("woff"), url("../fonts/myriad-pro/myriadpro-bold/myriadpro-bold.ttf") format("truetype"), url("../fonts/myriad-pro/myriadpro-bold/myriadpro-bold.svg#fontname") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Myriad Pro";
  src: url("../fonts/myriad-pro/myriadpro-regular/myriadpro-regular.eot");
  src: url("../fonts/myriad-pro/myriadpro-regular/myriadpro-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/myriad-pro/myriadpro-regular/myriadpro-regular.woff") format("woff"), url("../fonts/myriad-pro/myriadpro-regular/myriadpro-regular.ttf") format("truetype"), url("../fonts/myriad-pro/myriadpro-regular/myriadpro-regular.svg#fontname") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Myriad Pro";
  src: url("../fonts/myriad-pro/myriadpro-light/myriadpro-light.eot");
  src: url("../fonts/myriad-pro/myriadpro-light/myriadpro-light.eot?#iefix") format("embedded-opentype"), url("../fonts/myriad-pro/myriadpro-light/myriadpro-light.woff") format("woff"), url("../fonts/myriad-pro/myriadpro-light/myriadpro-light.ttf") format("truetype"), url("../fonts/myriad-pro/myriadpro-light/myriadpro-light.svg#fontname") format("svg");
  font-weight: 300;
  font-style: normal; }

.l-wrapper {
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  display: flex; }
  .l-wrapper main {
    flex-grow: 1; }

body {
  font-family: "Myriad Pro"; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 700; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; }

/* ==========================================
  Single-colored icons can be modified like so:
  .icon-name {
	font-size: 32px;
	color: red;
  }
  ========================================== */
.icon_online_payment {
  width: 1.400820793433653; }

.icon {
  font-size: 5rem;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto; }

.icon-logo {
  position: static;
  width: 3.477961432506887em;
  font-size: 8.7rem; }
  @media (max-width: 480px) {
    .icon-logo {
      font-size: 4.5rem; } }

button {
  display: inline-block;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent; }

.btn {
  position: relative;
  z-index: 3;
  display: inline-block;
  border-radius: 1.6rem;
  border: none;
  font-size: 1.8rem;
  font-weight: 300;
  box-shadow: 4px 7px 8px rgba(0, 0, 0, 0.35);
  border: 0;
  background-color: transparent;
  color: #fff;
  transition: all 300ms ease;
  outline: none; }
  .btn:hover {
    color: #ffe89c;
    outline: none; }
  .btn:focus, .btn:active {
    outline: none; }
  .btn_light {
    font-weight: 300; }

.send-request {
  text-align: center; }

.btn_green {
  height: 4.1rem;
  line-height: 4.1rem;
  padding: 0; }
  .btn_green:before {
    content: '';
    position: absolute;
    left: -0.6rem;
    top: 0.6rem;
    z-index: -1;
    display: inline-block;
    width: 2.9rem;
    height: 2.9rem;
    background: #39b015;
    border-radius: 1rem;
    transform: rotate(45deg); }
    @media (max-width: 767px) {
      .btn_green:before {
        top: 0.2rem;
        left: -0.1rem;
        width: 2.1rem;
        height: 2.1rem;
        border-radius: 0.8rem; } }
  .btn_green:after {
    content: '';
    position: absolute;
    right: -0.6rem;
    top: 0.6rem;
    z-index: -1;
    display: inline-block;
    width: 2.9rem;
    height: 2.9rem;
    background: #72e350;
    border-radius: 1rem;
    transform: rotate(45deg); }
    @media (max-width: 767px) {
      .btn_green:after {
        top: 0.2rem;
        right: -0.1rem;
        width: 2.1rem;
        height: 2.1rem;
        border-radius: 0.8rem; } }
  @media (max-width: 767px) {
    .btn_green {
      padding: 0;
      height: 2.5rem;
      line-height: 2.5rem;
      font-size: 1.2rem; } }
  .btn_green span {
    background: linear-gradient(to left, #72e350, #39b015);
    padding-right: 3.5rem;
    padding-left: 3.5rem;
    border-radius: 1.6rem;
    position: relative;
    display: block; }
    @media (max-width: 767px) {
      .btn_green span {
        padding: 0 1rem;
        height: 2.5rem;
        line-height: 2.5rem;
        font-size: 1.2rem; } }

.btn_blue {
  padding: 0;
  background-image: -webkit-linear-gradient(right, #1db2e4 0%, #4790aa 100%);
  background-image: -o-linear-gradient(right, #1db2e4 0%, #4790aa 100%);
  background-image: linear-gradient(to left, #1db2e4 0%, #4790aa 100%); }
  .btn_blue:before {
    content: '';
    position: absolute;
    left: -0.9rem;
    top: 0.6rem;
    z-index: -1;
    display: inline-block;
    width: 4rem;
    height: 4rem;
    background: #4790aa;
    border-radius: 1rem;
    transform: rotate(45deg); }
  .btn_blue:after {
    content: '';
    position: absolute;
    right: -0.9rem;
    top: 0.6rem;
    z-index: -1;
    display: inline-block;
    width: 4rem;
    height: 4rem;
    background: #1db2e4;
    border-radius: 1rem;
    transform: rotate(45deg); }
  .btn_blue span {
    height: 5.3rem;
    line-height: 5.3rem;
    background: linear-gradient(to left, #1db2e4 0%, #4790aa 100%);
    border-radius: 1.6rem;
    position: relative;
    display: block; }

.modal-content {
  border-radius: 1.5rem; }

.modal-header {
  position: relative;
  border-bottom: none; }

.modal-body {
  padding: 2rem 3.5rem 3.5rem 3.5rem; }

.modal-title {
  margin-top: 3.1rem;
  width: 100%;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 700;
  color: #4cd1fd; }

.modal .close {
  position: absolute;
  top: 0;
  right: 1rem;
  padding: 1rem;
  height: auto;
  box-sizing: border-box; }
  .modal .close span {
    display: inline-block;
    width: 1.3rem;
    height: 0.2rem;
    font-weight: 400;
    background-color: #4cd1fd;
    transform: rotate(45deg); }
    .modal .close span:after {
      position: absolute;
      top: 0;
      right: 0;
      content: '';
      display: inline-block;
      width: 1.3rem;
      height: 0.2rem;
      font-weight: 400;
      background-color: #4cd1fd;
      transform: rotate(-90deg); }

.form-control {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
  max-width: 18rem;
  height: 4.7rem;
  box-shadow: inset 3px 4px 5px rgba(0, 0, 0, 0.45);
  background-color: #ffffff;
  border: none;
  border-radius: 1rem;
  font-size: 2rem; }
  .form-control::placeholder {
    transition: opacity 0.3s ease;
    opacity: 1; }
  .form-control:hover {
    box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, 0.45); }
  .form-control:focus::placeholder {
    opacity: 0; }
  .form-control.error {
    border-color: #ff0000 !important;
    background-color: #ffdede; }
  .form-control:disabled .form-control, .form-control[disabled] .form-control, .form-control.disabled .form-control {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }

.form-group {
  display: inline-block;
  margin-bottom: 0; }
  .form-group:disabled .form-control, .form-group[disabled] .form-control, .form-group.disabled .form-control {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }

.input-wrap,
.select-wrap {
  position: relative; }

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%; }

.bootstrap-select > .dropdown-toggle.dropdown-toggle, .bootstrap-select > .dropdown-toggle.dropdown-toggle:hover, .bootstrap-select > .dropdown-toggle.dropdown-toggle:focus, .bootstrap-select > .dropdown-toggle.dropdown-toggle:active, .bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  outline: none !important; }

label {
  margin-bottom: 0;
  font-weight: 300;
  cursor: pointer; }
  label.error {
    color: #ff0000; }

.hidden-input {
  visibility: hidden !important;
  position: absolute !important;
  z-index: -1 !important;
  opacity: 0 !important;
  height: 0 !important;
  width: 0 !important;
  left: 0 !important;
  top: 0 !important; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.field_clear_btn {
  transition: color 0.3s ease;
  margin-bottom: auto;
  position: absolute;
  margin-top: auto;
  display: block;
  color: #d8d8d8;
  height: 30px;
  width: 30px;
  bottom: 0;
  right: 0;
  top: 0; }
  .field_clear_btn:hover {
    border-color: #b61f27;
    color: #b61f27; }
  .field_clear_btn:before, .field_clear_btn:after {
    background-color: currentColor;
    position: absolute;
    display: block;
    margin: auto;
    content: '';
    height: 2px;
    width: 16px;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0; }
  .field_clear_btn:before {
    transform: rotate(45deg); }
  .field_clear_btn:after {
    transform: rotate(-45deg); }

.checkbox-label {
  position: relative;
  padding-left: 30px;
  font-size: 1.4rem;
  line-height: 1.5rem; }
  .checkbox-label:disabled, .checkbox-label[disabled], .checkbox-label.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }
  .checkbox-label .check-icon {
    position: absolute;
    display: block;
    height: 2.6rem;
    width: 2.6rem;
    left: 0;
    top: 2px;
    box-shadow: inset 3px 4px 5px rgba(0, 0, 0, 0.45);
    background-color: #ffffff;
    border: none;
    border-radius: 0.5rem; }
    .checkbox-label .check-icon:before {
      transform: rotate(-52.5deg) skew(-15deg);
      transition: opacity 0.3s ease;
      border-bottom: solid 3px #72e350;
      border-left: solid 3px #39b015;
      position: absolute;
      display: block;
      margin: auto;
      content: '';
      height: 10px;
      width: 18px;
      opacity: 0;
      top: -8px;
      left: 4px;
      bottom: 0;
      right: 0; }
  .checkbox-label .check-value {
    transition: color 0.3s ease;
    color: #323232;
    text-align: left; }
  .checkbox-label input:checked ~ .check-icon:before {
    opacity: 1; }
  .checkbox-label input:disabled ~ .check-icon {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }
  .checkbox-label input:disabled ~ .check-value {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }

.radio-label {
  padding-left: 20px;
  position: relative; }
  .radio-label:disabled, .radio-label[disabled], .radio-label.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }
  .radio-label .check-icon {
    border: solid 1px #0171a6;
    border-radius: 50%;
    position: absolute;
    display: block;
    height: 13px;
    width: 13px;
    top: 4px;
    left: 0; }
    .radio-label .check-icon:before {
      transition: opacity 0.3s ease;
      background-color: #0171a6;
      border-radius: 50%;
      position: absolute;
      display: block;
      margin: auto;
      content: '';
      height: 5px;
      width: 5px;
      opacity: 0;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0; }
  .radio-label .check-value {
    transition: color 0.3s ease;
    color: #979797; }
  .radio-label.label_grey .check-value {
    color: #979797; }
  .radio-label input:checked ~ .check-icon:before {
    opacity: 1; }
  .radio-label input:disabled ~ .check-icon {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }
  .radio-label input:disabled ~ .check-value {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }

.footer {
  padding: 2.7rem 0 5.7rem;
  background-color: #262930;
  flex-shrink: 0; }
  .footer-container {
    display: flex; }
  .footer-contacts {
    margin-top: 0.1rem;
    flex-shrink: 0; }
    .footer-contacts-info {
      line-height: 1.9rem;
      margin-bottom: 0;
      font-size: 1.6rem;
      color: #fff;
      font-weight: 300; }
      .footer-contacts-info__name, .footer-contacts-info__addrs, .footer-contacts-info__time-work {
        margin-bottom: 0; }
  .footer-lic {
    margin-top: 5.8rem;
    padding: 0 7.5rem;
    text-align: center; }
    .footer-lic__deskr {
      margin-bottom: 0.5rem;
      line-height: 1.8rem;
      text-align: left;
      font-size: 1.4rem;
      font-weight: 300;
      color: #fff; }
    .footer-lic__link {
      line-height: 2rem;
      font-size: 1.6rem;
      font-weight: 300;
      color: #4cd1fd; }

@media (max-width: 1199px) {
  .footer-container {
    flex-wrap: wrap;
    justify-content: space-between; }
  .footer-contacts {
    margin-bottom: 3rem;
    width: 25%;
    order: 1; }
    .footer-contacts-info__name, .footer-contacts-info__addrs, .footer-contacts-info__time-work {
      font-size: 1.6rem; }
  .footer-lic {
    padding: 0 4rem;
    width: 45%;
    order: 2; }
  .send-request {
    order: 3; } }

@media (max-width: 992px) {
  .footer-container {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 4rem; }
  .footer-contacts {
    margin-bottom: 3rem;
    width: 40%;
    order: 1; }
  .footer-lic {
    margin-top: 0;
    padding: 0;
    width: 100%;
    order: 3; }
  .send-request {
    order: 2; } }

@media (max-width: 767px) {
  .footer-container {
    flex-wrap: wrap;
    padding: 0;
    max-width: 30rem;
    margin: auto; }
  .footer-contacts {
    margin-bottom: 1rem;
    width: 100%; }
    .footer-contacts-info__name, .footer-contacts-info__addrs, .footer-contacts-info__time-work {
      font-size: 1.4rem; }
  .footer-lic {
    margin-top: 2rem;
    padding: 0;
    width: 100%;
    order: 3; }
    .footer-lic__link {
      font-size: 1.4rem; }
  __send-request {
    margin-bottom: 0;
    order: 2; } }

.c-header {
  position: absolute;
  flex-shrink: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10; }
  .c-header-inner {
    position: relative;
    padding-top: 1.5rem;
    display: flex;
    flex-wrap: wrap; }
  .c-header__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%; }

.logo {
  width: auto; }
  @media (max-width: 767px) {
    .logo {
      width: 40%; } }

.send-request {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .send-request__phone {
    margin-bottom: 0.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    transition: color 0.3s ease;
    text-decoration: none;
    font-size: 2.4rem;
    font-weight: 700;
    color: #fff; }
    @media (max-width: 1199px) {
      .send-request__phone {
        font-size: 2rem; } }
    @media (max-width: 767px) {
      .send-request__phone {
        height: 2rem;
        font-size: 1.2rem; } }
    .send-request__phone:hover {
      color: #4cd1fd; }

.c-advantages {
  background-image: url("../images/useful/bg/header-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  .c-advantages__inner {
    padding: 18rem 0 16.8rem;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 26, 0.75); }
    @media (max-width: 767px) {
      .c-advantages__inner {
        padding: 12rem 0 12rem; } }
  .c-advantages__title {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 4.8rem;
    color: #fff; }
    @media (max-width: 1199px) {
      .c-advantages__title {
        font-size: 3rem; } }
    @media (max-width: 767px) {
      .c-advantages__title {
        font-size: 2rem; } }
  .c-advantages__text {
    width: 100%;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 300;
    color: #fff; }
    @media (max-width: 1199px) {
      .c-advantages__text {
        margin-bottom: 2rem;
        font-size: 2rem; } }
    @media (max-width: 767px) {
      .c-advantages__text {
        font-size: 1.6rem;
        line-height: 1.5rem; } }
  .c-advantages-wrap-item {
    padding: 0 15rem;
    display: flex;
    justify-content: space-between;
    width: 100%; }
    @media (max-width: 1199px) {
      .c-advantages-wrap-item {
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 5rem; } }
    @media (max-width: 767px) {
      .c-advantages-wrap-item {
        padding: 0 1rem; } }
  .c-advantages-item {
    width: 17rem; }
    @media (max-width: 1199px) {
      .c-advantages-item {
        width: 25%; } }
    .c-advantages-item__icon {
      position: relative;
      margin: auto;
      margin-bottom: 1rem;
      width: 11.9rem;
      height: 11.9rem;
      border: 1px solid rgba(112, 225, 78, 0.2);
      border-radius: 50%; }
      .c-advantages-item__icon:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: block;
        width: 9.4rem;
        height: 9.4rem;
        border: 3px solid #70e14e;
        border-radius: 50%; }
      .c-advantages-item__icon img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto; }
    .c-advantages-item__text {
      margin-bottom: 0;
      text-align: center;
      color: #fff;
      line-height: 2rem;
      font-size: 1.6rem;
      font-weight: 300; }
      @media (max-width: 1199px) {
        .c-advantages-item__text {
          font-size: 1.6rem; } }

@media (max-width: 991px) {
  .c-advantages-item {
    width: 45%;
    margin-bottom: 3rem; }
    .c-advantages-item__icon {
      width: 10.9rem;
      height: 10.9rem; }
      .c-advantages-item__icon:before {
        width: 8.4rem;
        height: 8.4rem; }
      .c-advantages-item__icon img {
        width: 40%; }
    .c-advantages-item__text {
      font-size: 1.6rem; } }

.c-work-info-section {
  position: relative;
  z-index: 4; }
  .c-work-info-section .container {
    position: relative;
    z-index: 3;
    top: -9.7rem; }

.c-work-info-section__bg {
  position: absolute;
  top: -9rem;
  left: -100px;
  bottom: -4rem;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  width: calc(100% + 200px);
  transform: rotate(-5deg); }
  .c-work-info-section__bg:before {
    content: '';
    position: absolute;
    top: -10rem;
    left: 0;
    right: 0;
    bottom: -10rem;
    display: block;
    background-image: url("../images/useful/bg/work-info-bg.jpg");
    background-position: center;
    background-size: cover;
    transform: rotate(5deg); }

.c-work-info {
  padding: 10.2rem 0 6.2rem;
  display: flex;
  justify-content: space-between; }
  .c-work-info-item {
    width: 18rem; }
    .c-work-info-item__icon {
      position: relative;
      margin: 0 auto;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 9.7rem;
      height: 9.7rem;
      border: 1px solid rgba(25, 196, 252, 0.2);
      border-radius: 50%; }
      .c-work-info-item__icon:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: block;
        width: 7.7rem;
        height: 7.7rem;
        border: 1px solid #19c4fc;
        border-radius: 50%; }
      .c-work-info-item__icon-text {
        color: #4cd1fd;
        font-size: 30px;
        font-weight: 700; }
    .c-work-info-item__text {
      font-size: 1.8rem;
      line-height: 2.2rem;
      text-align: center; }
      @media (max-width: 1199px) {
        .c-work-info-item__text {
          font-size: 1.6rem;
          line-height: 1.8rem; } }

@media (max-width: 767px) {
  .c-work-info {
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 5rem 0; }
    .c-work-info-item {
      width: 45%; }
      .c-work-info-item__text {
        font-size: 1.4rem; } }

.c-offer {
  position: relative;
  z-index: 2;
  margin-top: -18.3rem;
  background-image: url("../images/useful/bg/offer-bg-new.png");
  background-position: bottom;
  background-size: 100% 100%;
  background-repeat: no-repeat; }
  .c-offer-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14.4rem 0 21rem;
    position: relative;
    z-index: 2; }
    @media (max-width: 991px) {
      .c-offer-inner {
        padding: 15rem 0 8rem; } }
    @media (max-width: 767px) {
      .c-offer-inner {
        padding: 12rem 0 8rem; } }
  .c-offer-caption__title {
    width: 70%;
    margin: auto;
    text-align: center;
    font-size: 3.6rem;
    font-weight: 400;
    margin-bottom: 0.9rem;
    color: #4cd1fd; }
  .c-offer-caption__text {
    margin-bottom: 3.4rem;
    text-align: center;
    color: #fff;
    font-size: 2.1rem;
    font-weight: 300; }
  .c-offer-wrap {
    display: flex;
    margin-bottom: 4.8rem; }
    .c-offer-wrap__item {
      padding: 4rem  1rem;
      width: 29rem;
      text-align: center;
      border: 1px solid #fff; }
      .c-offer-wrap__item-img-cont {
        position: relative;
        margin-bottom: 2rem;
        height: 5.6rem; }
        .c-offer-wrap__item-img-cont .icon {
          font-size: 6.2rem; }
      .c-offer-wrap__item-title {
        font-size: 1.7rem;
        margin-bottom: 1.5rem;
        color: #4cd1fd;
        text-transform: uppercase; }
      .c-offer-wrap__item-list {
        padding: 0; }
      .c-offer-wrap__item-item {
        color: #fff;
        list-style: none;
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 2.1rem; }

.c-offer-button-cont {
  text-align: center; }

@media (max-width: 1200px) {
  .c-offer {
    background-size: auto 100%;
    background-position: center; } }

@media (max-width: 991px) {
  .c-offer {
    background-size: auto 130%;
    background-position: center 30%; }
    .c-offer-caption__title {
      width: 100%;
      font-size: 2.5rem; }
    .c-offer-caption__text {
      font-size: 2rem;
      line-height: 2rem; }
    .c-offer-wrap {
      flex-wrap: wrap; }
      .c-offer-wrap__item {
        width: 50%; }
        .c-offer-wrap__item-title {
          font-size: 2rem; }
        .c-offer-wrap__item-item {
          font-size: 1.6rem; }
  .btn_blue {
    font-size: 1.5rem; } }

@media (max-width: 992px) {
  .c-offer {
    background-size: auto 124%;
    background-position: center 30%; }
    .c-offer-caption__title {
      font-size: 3rem; }
    .c-offer-caption__text {
      font-size: 2rem; }
    .c-offer-wrap {
      flex-wrap: wrap; }
      .c-offer-wrap__item {
        width: 50%; }
        .c-offer-wrap__item-title {
          font-size: 1.6rem; }
        .c-offer-wrap__item-item {
          font-size: 1.2rem; } }

@media (max-width: 767px) {
  .c-offer {
    background-size: auto 130%;
    background-position: center 30%; }
    .c-offer-caption {
      width: 100%; }
      .c-offer-caption__title {
        font-size: 2.5rem; }
      .c-offer-caption__text {
        font-size: 1.6rem;
        line-height: 2rem; }
    .c-offer-wrap {
      flex-wrap: wrap; }
      .c-offer-wrap__item {
        width: 100%;
        font-size: 1.6rem; }
  .btn_blue {
    font-size: 1.5rem; } }

.c-warranties {
  margin-top: -35rem;
  position: relative;
  z-index: 1; }
  .c-warranties-inner {
    position: relative;
    z-index: 1;
    background-image: url("../images/useful/bg/warranties-bg.jpg");
    background-size: cover;
    background-position: center; }
  .c-warranties-block {
    position: relative;
    margin-top: -20rem;
    width: 50%;
    padding: 34.7rem 0 3rem;
    z-index: 2; }
    .c-warranties-block_bg {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
      opacity: 0.8; }
    .c-warranties-block__title {
      margin-bottom: 2rem;
      color: #4cd1fd;
      font-size: 4.8rem;
      font-weight: 400;
      line-height: 2.9rem; }
    .c-warranties-block__text-title {
      margin-bottom: 2.5rem;
      color: #212121;
      line-height: 2.5rem;
      font-size: 2.1rem;
      font-weight: 400; }
    .c-warranties-block__text {
      margin-bottom: 2.5rem;
      color: #212121;
      font-size: 1.8rem;
      font-weight: 300;
      line-height: 2.9rem; }
    .c-warranties-block__text span {
      color: #27b4e5;
      font-size: 2.4rem;
      font-weight: 700; }
  .c-warranties-button-cont {
    text-align: center; }
    .c-warranties-button-cont__text {
      font-size: 2.1rem; }

@media (max-width: 1199px) {
  .c-warranties-block {
    margin-top: 16rem;
    padding-top: 16rem;
    width: 100%; }
    .c-warranties-block__title {
      font-size: 2.5rem; }
    .c-warranties-block__text-title {
      line-height: 2rem;
      font-size: 1.6rem; }
    .c-warranties-block__text {
      font-size: 1.6rem;
      line-height: 2rem; }
      .c-warranties-block__text span {
        font-size: 2rem; } }

@media (max-width: 991px) {
  .c-warranties-block {
    margin-top: 28.4rem;
    padding-top: 10rem;
    width: 100%; }
    .c-warranties-block__title {
      font-size: 2.5rem; }
    .c-warranties-block__text-title {
      line-height: 2rem;
      font-size: 1.6rem; }
    .c-warranties-block__text {
      font-size: 1.6rem;
      line-height: 2rem; }
      .c-warranties-block__text span {
        font-size: 2rem; } }

@media (max-width: 767px) {
  .c-warranties-button-cont__text {
    font-size: 1.6rem;
    line-height: 2rem; } }

.c-clients {
  background-image: url("../images/useful/bg/clients-bg.png");
  background-size: 100% 100%;
  background-position: bottom; }
  .c-clients-container {
    padding-top: 10rem; }
    @media (max-width: 767px) {
      .c-clients-container {
        padding-top: 5rem; } }
    .c-clients-container__title {
      margin-bottom: 5rem;
      text-align: center;
      color: #4cd1fd;
      font-size: 3.6rem;
      font-weight: 400; }
  .c-clients-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
    .c-clients-wrap__link {
      width: 20.5%;
      margin-bottom: 5rem; }
    .c-clients-wrap__img {
      display: block;
      margin: 0 auto; }
    .c-clients-wrap__deskr {
      margin-top: -5rem;
      margin-bottom: 20rem;
      width: 100%;
      font-size: 2.4rem;
      font-weight: 300;
      color: #262930; }
      @media (max-width: 1199px) {
        .c-clients-wrap__deskr {
          font-size: 1.6rem; } }

@media (max-width: 1199px) {
  .c-clients-container__title {
    font-size: 3rem; }
  .c-clients-wrap__link {
    padding: 0 1rem;
    width: 25%; } }

@media (max-width: 991px) {
  .c-clients {
    background-size: auto 100%;
    background-position: bottom; }
    .c-clients-container__title {
      font-size: 2.5rem; }
    .c-clients-wrap__link {
      width: 33%; } }

.c-action-container {
  justify-content: flex-end;
  position: relative;
  display: flex; }

.c-action-section {
  margin-top: -6.2rem;
  margin-bottom: 16rem; }
  @media (max-width: 767px) {
    .c-action-section {
      margin-bottom: 10rem; } }

.c-action-img {
  position: absolute;
  top: 3.5rem;
  left: -8rem;
  z-index: 4;
  width: 84.8rem;
  pointer-events: none; }

.c-action-form-inner {
  position: relative;
  width: 50.5rem;
  right: 0; }
  .c-action-form-inner:before {
    content: '';
    position: absolute;
    left: -3rem;
    z-index: 1;
    width: 100vw;
    border-radius: 2rem;
    background-image: linear-gradient(to top, #24a1ca 0%, #71d9fb 57%, #71d9fb 100%);
    opacity: 0.25;
    top: 3.6rem;
    bottom: -3.4rem; }
  .c-action-form-inner:after {
    top: 0;
    bottom: 0;
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 100%;
    width: 100vw;
    box-shadow: inset -20px 3px 30px 5px rgba(87, 204, 243, 0.1);
    background-color: #fff;
    border: 1px solid #57ccf3;
    border-left: none; }

.c-action-form {
  padding: 3rem 0 4.4rem 3rem;
  box-shadow: inset 20px 3px 30px 5px rgba(87, 204, 243, 0.1);
  border: 1px solid #57ccf3;
  border-right: none;
  border-radius: 2rem 0 0 2rem;
  background-color: #ffffff;
  position: relative;
  z-index: 3; }
  .c-action-form__title {
    margin-bottom: 0.9rem;
    font-size: 3.6rem;
    font-weight: 700;
    color: #57ccf3; }
    .c-action-form__title_down {
      margin-bottom: 0.2rem; }
  .c-action-form__descr {
    padding-left: 0.4rem;
    color: #1a1a1a;
    font-size: 2.1rem;
    margin-bottom: 3rem;
    line-height: 2.9rem;
    font-weight: 300; }
    .c-action-form__descr_down {
      margin-bottom: 1.1rem; }

.form__input-phone {
  margin-right: 1rem;
  margin-bottom: 1.5rem;
  width: 23.1rem;
  height: 4.7rem;
  box-shadow: inset 3px 4px 5px rgba(0, 0, 0, 0.45);
  background-color: #ffffff;
  border: none;
  border-radius: 1rem;
  font-size: 2rem; }

.form__btn {
  margin-bottom: 1rem; }

.form__label-lic-check {
  margin-left: 0.5rem;
  vertical-align: top;
  width: 2.6rem;
  height: 2.7rem;
  box-shadow: inset 3px 4px 5px rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
  border: none;
  border-radius: 1rem; }

.form__label-lic span {
  text-align: left;
  margin-top: 0.4rem;
  margin-left: 1.5rem;
  display: inline-block;
  width: 85%;
  color: #1a1a1a;
  line-height: 1.5rem;
  font-size: 1.4rem;
  font-weight: 300; }

@media (max-width: 1199px) {
  .c-action-img {
    max-width: 80%;
    top: 10rem;
    left: -15rem;
    z-index: 4;
    width: 84.8rem;
    pointer-events: none; } }

@media (max-width: 991px) {
  .c-action-img {
    top: 10rem;
    left: -17rem;
    z-index: 4;
    width: 94.8rem;
    max-width: 100%;
    pointer-events: none; }
  .c-action-form-inner {
    width: 37.5rem; }
  .c-action-form__title {
    font-size: 2.3rem; }
  .c-action-form__descr {
    font-size: 1.5rem;
    line-height: 2rem; }
  .form__input-phone {
    width: 100%;
    height: 3rem; }
  .form__label-lic-check {
    width: 2rem;
    height: 2rem;
    box-shadow: inset 3px 4px 5px rgba(0, 0, 0, 0.4); }
  .form__label-lic span {
    text-align: left;
    font-size: 1rem;
    line-height: 1.2rem;
    width: 80%; }
  .form__label-lic br {
    display: none; } }

@media (max-width: 767px) {
  .c-action-img {
    display: none; }
  .c-action-form-inner {
    width: 100%; } }

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.c-certificates-container__title {
  margin-bottom: 6rem;
  text-align: center;
  color: #4cd1fd;
  font-size: 4.8rem;
  font-weight: 400; }

.c-certificates-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; }
  .c-certificates-wrap__link {
    width: 25%;
    margin-bottom: 5rem; }
  .c-certificates-wrap__img {
    display: block;
    margin: 0 auto; }

@media (max-width: 1199px) {
  .c-certificates-container__title {
    margin-bottom: 3rem;
    font-size: 3rem; } }

@media (max-width: 767px) {
  .c-certificates-container__title {
    font-size: 2.5rem; }
  .c-certificates-wrap {
    flex-wrap: wrap; }
    .c-certificates-wrap__link {
      width: 45%; } }

.c-we-work {
  padding-top: 10.5rem;
  background-image: url("../images/useful/bg/we-work-bg.png");
  background-size: 100% 130%;
  background-position: top 30%;
  background-repeat: no-repeat;
  padding-bottom: 4.3rem; }
  @media (max-width: 767px) {
    .c-we-work {
      padding-top: 5rem;
      padding-bottom: 0; } }
  .c-we-work-wrap {
    display: flex;
    justify-content: space-between; }
  .c-we-work__title {
    margin-bottom: 8rem;
    text-align: center;
    color: #4cd1fd;
    font-size: 4.8rem;
    font-weight: 400; }
  .c-we-work-item {
    width: 16rem;
    position: relative; }
    .c-we-work-item:not(:first-child):before {
      content: '';
      position: absolute;
      left: -1.4rem;
      top: 3rem;
      display: block;
      height: 2.4rem;
      width: 2.4rem;
      transform: rotate(45deg);
      border-top: 1px solid #4cd1fd;
      border-right: 1px solid #4cd1fd; }
    .c-we-work-item:not(:first-child):after {
      content: '';
      position: absolute;
      left: -3rem;
      top: 3rem;
      display: block;
      height: 2.4rem;
      width: 2.4rem;
      transform: rotate(45deg);
      border-top: 1px solid rgba(76, 209, 253, 0.2);
      border-right: 1px solid rgba(76, 209, 253, 0.2); }
    .c-we-work-item__icon {
      margin: 0 auto;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 8.7rem;
      height: 8.7rem;
      border: 1px solid rgba(25, 196, 252, 0.2);
      border-radius: 50%; }
      .c-we-work-item__icon_circl {
        position: relative;
        width: 6.9rem;
        height: 6.9rem;
        border: 1px solid #19c4fc;
        border-radius: 50%; }
        .c-we-work-item__icon_circl .icon {
          color: #4cd1fd;
          font-size: 4rem; }
    .c-we-work-item__text {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 2rem;
      text-align: center; }
      @media (max-width: 1199px) {
        .c-we-work-item__text {
          font-size: 1.2rem;
          line-height: 1.6rem; } }

@media (max-width: 992px) {
  .c-we-work__title {
    margin-bottom: 3rem;
    font-size: 3rem; }
  .c-we-work-wrap {
    flex-wrap: wrap;
    justify-content: space-around; }
  .c-we-work-item {
    width: 25%; } }

@media (max-width: 767px) {
  .c-we-work {
    background-size: auto 150%;
    background-position: center 25%; }
    .c-we-work__title {
      font-size: 2.5rem;
      margin-bottom: 0; }
    .c-we-work-wrap {
      flex-wrap: wrap;
      margin: auto;
      padding: 5rem 0 0;
      width: 80%; }
    .c-we-work-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 4rem;
      width: 100%; }
      .c-we-work-item__icon {
        flex-shrink: 0;
        margin: 0; }
      .c-we-work-item__text {
        margin-left: 3rem;
        width: 100%;
        text-align: left;
        font-size: 1.6rem; }
      .c-we-work-item:not(:first-child):before {
        left: 3rem;
        top: -3.6rem;
        transform: rotate(135deg); }
      .c-we-work-item:not(:first-child):after {
        left: 3rem;
        top: -4.6rem;
        transform: rotate(135deg); } }

.footer {
  padding: 2.7rem 0 5.7rem;
  background-color: #262930;
  flex-shrink: 0; }
  .footer-container {
    display: flex; }
  .footer-contacts {
    margin-top: 0.1rem;
    flex-shrink: 0; }
    .footer-contacts-info {
      line-height: 1.9rem;
      margin-bottom: 0;
      font-size: 1.6rem;
      color: #fff;
      font-weight: 300; }
      .footer-contacts-info__name, .footer-contacts-info__addrs, .footer-contacts-info__time-work {
        margin-bottom: 0; }
  .footer-lic {
    margin-top: 5.8rem;
    padding: 0 7.5rem;
    text-align: center; }
    .footer-lic__deskr {
      margin-bottom: 0.5rem;
      line-height: 1.8rem;
      text-align: left;
      font-size: 1.4rem;
      font-weight: 300;
      color: #fff; }
    .footer-lic__link {
      line-height: 2rem;
      font-size: 1.6rem;
      font-weight: 300;
      color: #4cd1fd; }

@media (max-width: 1199px) {
  .footer-container {
    flex-wrap: wrap;
    justify-content: space-between; }
  .footer-contacts {
    margin-bottom: 3rem;
    width: 25%;
    order: 1; }
    .footer-contacts-info__name, .footer-contacts-info__addrs, .footer-contacts-info__time-work {
      font-size: 1.6rem; }
  .footer-lic {
    padding: 0 4rem;
    width: 45%;
    order: 2; }
  .send-request {
    order: 3; } }

@media (max-width: 992px) {
  .footer-container {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 4rem; }
  .footer-contacts {
    margin-bottom: 3rem;
    width: 40%;
    order: 1; }
  .footer-lic {
    margin-top: 0;
    padding: 0;
    width: 100%;
    order: 3; }
  .send-request {
    order: 2; } }

@media (max-width: 767px) {
  .footer-container {
    flex-wrap: wrap;
    padding: 0;
    max-width: 30rem;
    margin: auto; }
  .footer-contacts {
    margin-bottom: 1rem;
    width: 100%; }
    .footer-contacts-info__name, .footer-contacts-info__addrs, .footer-contacts-info__time-work {
      font-size: 1.4rem; }
  .footer-lic {
    margin-top: 2rem;
    padding: 0;
    width: 100%;
    order: 3; }
    .footer-lic__link {
      font-size: 1.4rem; }
  __send-request {
    margin-bottom: 0;
    order: 2; } }
