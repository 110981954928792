.c-advantages {
	background-image: url("../images/useful/bg/header-bg.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	&__inner {
		padding: 18rem 0 16.8rem; 
		width: 100%;
		height: 100%;
		background-color: rgba(26,26,26, 0.75);
		@media ( max-width: $sw-xs-max){
			padding: 12rem 0 12rem;
		}
	}

	&__title {
		margin-bottom: 1rem;
		text-align: center;
		font-size: 4.8rem;
		color: $white;

		@media ( max-width: $sw-md-max){
			font-size: 3rem;
		}

		@media ( max-width: $sw-xs-max){
			font-size: 2rem;
		}
	}
 
	&__text {
		width: 100%;
		text-align: center;
		font-size: 2.4rem;
		font-weight: 300;
		color: $white;

		@media ( max-width: $sw-md-max){
			margin-bottom: 2rem;
			font-size: 2rem;
		}

		@media ( max-width: $sw-xs-max){
			font-size: 1.6rem;
			line-height: 1.5rem;
		}
	}

	&-wrap {
		&-item {
			padding: 0 15rem;
			display: flex;
			justify-content: space-between;
			width: 100%;

			@media ( max-width: $sw-md-max){
				flex-wrap: wrap;
				justify-content: center;
				padding: 0 5rem;
			}
			@media ( max-width: $sw-xs-max){
				padding: 0 1rem;
			}
		}
	}

	&-item {
		width: 17rem;

		@media ( max-width: $sw-md-max){
			width: 25%;
		}

		&__icon {
			position: relative;
			margin: auto;
			margin-bottom: 1rem;
			width: 11.9rem;
			height: 11.9rem;
			border: 1px solid rgba(112, 225, 78, 0.2);
			border-radius: 50%;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				display: block;
				width: 9.4rem;
				height: 9.4rem;
				border: 3px solid #70e14e;
				border-radius: 50%;
			}

			img {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
			}

		}
		&__text {
			margin-bottom: 0;
			text-align: center;
			color: $white;
			line-height: 2rem;
			font-size: 1.6rem;
			font-weight: 300;
			@media ( max-width: $sw-md-max){
				font-size: 1.6rem;
			}
		}

	}
}

@media ( max-width: $sw-sm-max){
	.c-advantages-item {
		width: 45%;
		margin-bottom: 3rem;
		&__icon {
			width: 10.9rem;
			height: 10.9rem;
			&:before {
				width: 8.4rem;
				height: 8.4rem;
			}
			img {
				width: 40%;
			}
		}
		&__text {
			font-size: 1.6rem;
		}
	}
}