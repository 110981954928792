.l-wrapper{
	flex-direction: column;
	position: relative;
	min-height: 100vh;
	overflow: hidden;
	display: flex;

	main {
		flex-grow: 1;
	}
}

body {
	font-family: "Myriad Pro";
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: 700;
}