// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Table of Contents
//
// Custom Color system
$maya-blue: #4cd1fd;
$white: #fff;
$black: #000;
// Color system
// Options
$skew_angle: 5deg;
// Spacing
// Body
  //
  // Settings for the `<body>` element.

  $body-bg:       $white;
  $body-color:    $black;

// Links
  //
  // Style anchor elements.

  $link-decoration:       underline;
  $link-hover-decoration: none;

// Grid breakpoints
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$sw-xx-min:0;//0
$sw-xs-min:480px;//0//576
$sw-sm-min:768px;
$sw-md-min:992px;//768
$sw-lg-min:1200px;//1017

$sw-xx-max:$sw-xs-min - 1px;//479px
$sw-xs-max:$sw-sm-min - 1px;
$sw-sm-max:$sw-md-min - 1px;
$sw-md-max:$sw-lg-min - 1px;

$grid-breakpoints: (
	xs: $sw-xs-min,
	sm: $sw-sm-min,
	md: $sw-md-min,
	lg: $sw-lg-min,
);
// @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// @include _assert-starts-at-zero($grid-breakpoints);

// Grid columns
$grid-columns:               24;
$grid-gutter-width-base:     3rem;

$grid-gutter-widths: (
	xs: $grid-gutter-width-base,
	sm: $grid-gutter-width-base,
	md: $grid-gutter-width-base,
	lg: $grid-gutter-width-base
);
// Grid containers
$container-max-widths: (
	xs: 100%,
	sm: 75rem + $grid-gutter-width-base,
	md: 95rem + $grid-gutter-width-base,
	lg: 117.4rem + $grid-gutter-width-base,
);
// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// $headings-font-family: "Myriad Pro";

$font-size-base: 1.4rem; // Assumes the browser default, typically `14px`

$line-height-base: 2.14285714;

$h1-font-size: 3.6rem;
$h2-font-size: 2.6rem;
$h3-font-size: 2.4rem;
$h4-font-size: 2rem;
$h5-font-size: 1.8rem;
$h6-font-size: 1.6rem;

// Components
// Tables
// Buttons
// Forms
// Dropdowns
// Z-index master list
// Navs
// Navbar
// Pagination
// Jumbotron
// Form states and alerts
// Cards
// Tooltips
// Popovers
// Badges
// Modals
// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Close
// Code