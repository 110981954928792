@mixin font-face ($fontname, $fontfile, $fontweight: normal, $fontstyle: normal) {
	@font-face {
		font-family: $fontname;
		src: url('../fonts/' + $fontfile + '.eot');
		src: url('../fonts/' + $fontfile + '.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/' + $fontfile + '.woff') format('woff'),
			 url('../fonts/' + $fontfile + '.ttf') format('truetype'),
			 url('../fonts/' + $fontfile + '.svg##{fontname}') format('svg');
		font-weight: $fontweight;
		font-style: $fontstyle;
	}
};

@include font-face ('Myriad Pro', 'myriad-pro/myriadpro-bold/myriadpro-bold', 700, normal)
@include font-face ('Myriad Pro', 'myriad-pro/myriadpro-regular/myriadpro-regular', 400, normal)
@include font-face ('Myriad Pro', 'myriad-pro/myriadpro-light/myriadpro-light', 300, normal)