.c-header {
	position: absolute;
	flex-shrink: 0;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	&-inner {
		position: relative;
		padding-top: 1.5rem;
		display: flex;
		flex-wrap: wrap;
	}
	&__top {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
	}
}

.logo {
	width: auto;
	@media ( max-width: $sw-xs-max){
		width: 40%;
	}
}

.send-request {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	&__phone {
		margin-bottom: 0.5rem;
		height: 2.5rem;
		line-height: 2.5rem;
		transition: color 0.3s ease;
		text-decoration: none;
		font-size: 2.4rem;
		font-weight: 700;
		color: $white;

		@media ( max-width: $sw-md-max){
			font-size: 2rem;
		}

		@media ( max-width: $sw-xs-max){
			height: 2rem;
			font-size: 1.2rem;
		}
		&:hover {
			color: $maya-blue;
		}
	}
}
