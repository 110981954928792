.c-certificates {
	&-container {
		&__title {
			margin-bottom: 6rem;
			text-align: center;
			color: $maya-blue;
			font-size: 4.8rem;
			font-weight: 400;
		}
	}

	&-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;

		&__link {
			width: 25%;
			margin-bottom: 5rem;
		}

		&__img {
			display: block;
			margin: 0 auto;
		}
	}
}

@media ( max-width: $sw-md-max){
	.c-certificates {
		&-container__title {
			margin-bottom: 3rem;
			font-size: 3rem;
		}
	}
}

@media ( max-width: $sw-xs-max){
	.c-certificates {
		&-container__title {
			font-size: 2.5rem;
		}
		&-wrap {
			flex-wrap: wrap;
			&__link {
				width: 45%;
			}
		}
	}
}