.c-work-info-section {
	position: relative;
	z-index: 4;
	.container {
		position: relative;
		z-index: 3;
		top: -9.7rem;
	}
}

.c-work-info-section__bg {
	position: absolute;
	top: -9rem;
	left: -100px;
	bottom: -4rem;
	height: 100%;
	z-index: 1;
	overflow: hidden;
	width: calc(100% + 200px);
	transform: rotate(-$skew_angle);
	&:before {
		content: '';
		position: absolute;
		top: -10rem;
		left: 0;
		right: 0;
		bottom: -10rem;
		display: block;
		background-image: url("../images/useful/bg/work-info-bg.jpg");
		background-position: center;
		background-size: cover;
		transform: rotate($skew_angle);
	}
}

.c-work-info {
	padding: 10.2rem 0 6.2rem;
	display: flex;
	justify-content: space-between;
	&-item {
		width: 18rem;
		&__icon {
			position: relative;
			margin: 0 auto;
			margin-bottom: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 9.7rem;
			height: 9.7rem;
			border: 1px solid rgba(25, 196, 252, 0.2);
			border-radius: 50%;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				display: block;
				width: 7.7rem;
				height: 7.7rem;
				border: 1px solid #19c4fc;
				border-radius: 50%;
			}
			&-text {
				color: #4cd1fd;
				font-size: 30px;
				font-weight: 700;
			}
		}

		&__text {
			font-size: 1.8rem;
			line-height: 2.2rem;
			text-align: center;
			@media ( max-width: $sw-md-max){
				font-size: 1.6rem;
				line-height: 1.8rem;
			}
		}
	}
}


@media ( max-width: $sw-xs-max){
	.c-work-info {
		flex-wrap: wrap;
		justify-content: space-around;
		padding: 5rem 0;
		&-item {
			width: 45%;
			&__text {
				font-size: 1.4rem;
			}
		}
	}
}